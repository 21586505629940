import React from 'react';
import { Link } from 'react-router-dom';
import './BlogSection.css'; 
import newsData from '../data/newsData';  // İçe aktarımı doğru şekilde yap
import { useTranslation } from 'react-i18next';

const BlogSection = () => {
    const { t } = useTranslation(); // i18n kullanımı

    return (
        <div className="container my-5">
            <div className="row">
                <h2 className="text-center mb-5">{t('newsSection.title')}</h2>
                {newsData.map((news, index) => (  // news yerine doğrudan newsData kullan
                    <div className="col-md-3 d-flex" key={index}>
                        <div className="card blog-card">
                            <img src={news.image} className="card-img-top" alt={news.title} />
                            <div className="card-body d-flex flex-column">
                                <h5 className="card-title">{news.title}</h5> 
                                <a className="nav-link p-0 text-decoration-none " href={`/news/${news.id}`}>
                                    <button className="btn read-more-news">
                                        {t('newsSection.readMore')} &nbsp; <i className="fas fa-arrow-right"></i> 
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogSection;
