import React from 'react';
import './AboutContent.css';
import YouTubeChannel from './YoutubeChannel';
import Breadcrumb from '../components/Breadcrumb';
import aboutContentData from '../data/aboutContentData';

const AboutContent = () => {
  const { title, paragraphs } = aboutContentData;

  return (
    <>
      <div className="container my-5">
        <Breadcrumb />      
        <h1 className='text-start mb-3'>{title}</h1>
        {paragraphs.map((paragraph, index) => (
          <p key={index} className='text-start mb-4 mt-4'>
            {paragraph}
          </p>
        ))}
      </div>
      <YouTubeChannel />
      <div className='mt-5'></div>
    </>
  );
}

export default AboutContent;
