import React from 'react'
import MainBanner from '../components/MainBanner'
import FeaturedVideos from '../components/FeaturedVideos';
import YouTubeChannel from '../components/YoutubeChannel';
import BlogSection from '../components/BlogSection';
const Home = () => {
  return (
    <div>
      <MainBanner/>
      <FeaturedVideos/>
      <YouTubeChannel/>
      <BlogSection/>
    </div>
  )
}

export default Home