import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { blogData } from '../data/blogData';
import Breadcrumb from './Breadcrumb'; 
import './BlogDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const LazyLoadImage = ({ src, alt, className }) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        imgRef.current.src = src;  // Görünüm alanına girdiğinde resmi yükle
        observer.unobserve(imgRef.current);  // Yüklendikten sonra observer'ı durdur
      }
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [src]);

  return <img ref={imgRef} alt={alt} className={className} />;
};

const BlogDetail = () => {
  const { slug } = useParams();
  const { t, i18n } = useTranslation(); // i18n kullanımı
  const currentLang = i18n.language; // Aktif dili tespit et
  const blog = blogData.find((blog) => blog.slug === slug);

  const mostReadToShow = blogData.slice(0, 5); 

  return (
    <div className="container mt-5 blog-detail-container">
      <Breadcrumb /> 
      {blog ? (
        <div className="row">
          <div className="col-lg-8">
            <h1>{blog.title[currentLang] || blog.title['en']}</h1> {/* Aktif dilde başlık */}
            <p className="text-muted">{t('blogDetail.date')} 25.04.2024 - 13:57 | {t('blogDetail.updateDate')} 25.04.2024 - 13:36</p>
            <div className="blog-image-container">
              <LazyLoadImage 
                src={blog.image} 
                alt={blog.title[currentLang] || blog.title['en']} 
                className="img-fluid blog-image mb-4" 
              />
            </div>
            <div className="blog-content">
              {blog.content[currentLang]?.map((paragraph, index) => (
                <p key={index} className='text-start text-muted'>{paragraph}</p>
              )) || blog.content['en'].map((paragraph, index) => ( // Eğer mevcut dilde içerik yoksa 'en' dilini göster
                <p key={index} className='text-start text-muted'>{paragraph}</p>
              ))}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="most-read-container">
              <h5 className="mb-3">📖 {t('blogDetail.mostRead')}</h5>
              {mostReadToShow.map((item, index) => (
                <div key={index} className="most-read-item mb-3">
                  <LazyLoadImage 
                    src={item.image} 
                    alt={item.title[currentLang] || item.title['en']} 
                    className="img-fluid" 
                  />
                  <div>
                    <h6 className='text-start'>{item.title[currentLang] || item.title['en']}</h6>
                    <p className='text-muted text-start'>{item.summary[currentLang] || item.summary['en']}
                      <br />
                      <a
                         href={`/blog/${item.slug}`}
                         style={{ textDecoration: 'none', color: '#e53935' }}
                         className='text-start read-more-most-read'
                     >
                         {t('blogDetail.readMore')} <FontAwesomeIcon icon={faArrowRight} />
                     </a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <h1>{t('blogDetail.notFound')}</h1>
      )}
    </div>
  );
};

export default BlogDetail;
