import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { blogData } from '../data/blogData';
import './BlogPage.css';
import SubsEmagazine from './SubsEmagazine';
import Breadcrumb from './Breadcrumb';
import { useTranslation } from 'react-i18next';

const LazyLoadImage = ({ src, alt, className }) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        imgRef.current.src = src;  // Görünüm alanına girdiğinde resmi yükle
        observer.unobserve(imgRef.current);  // Yüklendikten sonra observer'ı durdur
      }
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [src]);

  return <img ref={imgRef} alt={alt} className={className} />;
};

const BlogPage = () => {
  const { t, i18n } = useTranslation(); // i18n kullanımı
  const currentLang = i18n.language; // Aktif dili alıyoruz
  const blogs = blogData || [];

  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 9;

  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // İçeriği maksimum 90 karakterle sınırla, aktif dildeki özeti kullan
  const truncateContent = (content, maxLength) => {
    return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
  };

  return (
    <>
      <div className="container mt-5">
        <Breadcrumb />
        <h1 className="mb-4">{t('blogPage.title')}</h1>
        <div className="row">
          {currentBlogs.map((blog) => (
            <div key={blog.id} className="col-md-4 mb-3">
              <div className="card h-100">
                <LazyLoadImage 
                  src={blog.image} 
                  alt={blog.title[currentLang] || blog.title['en']} 
                  className="card-img-top" 
                />
                <div className="card-body">
                  <h5 className="card-title">{blog.title[currentLang] || blog.title['en']}</h5> {/* Aktif dilde başlık */}
                  <p className="card-text">
                    {truncateContent(blog.summary[currentLang] || blog.summary['en'], 90)} {/* Aktif dilde özet */}
                  </p>
                  <a href={`/blog/${blog.slug}`} className="btn btn-primary mt-auto">
                    {t('blogPage.readMore')}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Sayfalama */}
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-center mt-4">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={handlePreviousPage} aria-label={t('blogPage.previous')}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button className="page-link" onClick={handleNextPage} aria-label={t('blogPage.next')}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div className='mt-5'></div>
      <SubsEmagazine />
    </>
  );
};

export default BlogPage;
