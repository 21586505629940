import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'; // Custom styling
import LogoFooter from '../assets/Logos/fikir_paneli_logo_red.png';
import { useTranslation } from 'react-i18next'; // i18n import

const Footer = () => {
    const { t } = useTranslation(); // Translation hook

    return (
      <>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <img src={LogoFooter} alt="Fikir paneli logo" className='footer-logo' />
                        <p></p>
                        <p>{t('footer.support_contact')}</p>
                        <p><a href="tel:+90 850 241 0804" style={{ textDecoration: 'none', color: 'inherit'  }}> {t('footer.sales_line')}: +90 850 241 0804 </a></p>
                        <p><a href="mailto:info@fikirpaneli.com.tr" style={{ textDecoration: 'none', color: 'inherit'  }}> {t('footer.email')}: info@fikirpaneli.com.tr </a></p>
                        <h6>{t('footer.social_media')}</h6>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/fikirpanel"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a href="https://www.instagram.com/fikirpanel/"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="https://www.youtube.com/@Fikirpaneli"><FontAwesomeIcon icon={faYoutube} /></a>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h6>{t('footer.corporate')}</h6>
                        <ul>
                            <li><a href="/About">{t('footer.about_us')}</a></li>
                            <li><a href="/video">{t('footer.video')}</a></li>
                            <li><a href="/blog">{t('footer.blog')}</a></li>
                            <li><a href="/contact">{t('footer.contact')}</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h6>{t('footer.categories')}</h6>
                        <ul>
                            <li><a href="/Culture">{t('footer.culture_art')}</a></li>
                            <li><a href="/Science">{t('footer.science_technology')}</a></li>
                            <li><a href="/PersonalDevelopment">{t('footer.personal_development')}</a></li>
                            <li><a href="/Sociology">{t('footer.sociology')}</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h6>{t('footer.customer_center')}</h6>
                        <ul>
                            <li><a href="/kvkk-policy">{t('footer.kvkk_policy')}</a></li>
                            <li><a href="/cookie-policy">{t('footer.cookie_policy')}</a></li>
                            <li><a href="/privacy-policy">{t('footer.privacy_policy')}</a></li>
                            <li><a href="/fikirpaneli">{t('footer.magazine')}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <div className="footer-bottom">
           <p>© {t('footer.copyright')}</p>
        </div>
      </>
    );
};

export default Footer;
