import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="container mt-5 mb-5 text-muted fs-7 text-start">
      <h2 className="mb-4">{t('privacyPolicy.title')}</h2>
      <p>{t('privacyPolicy.intro')}</p>

      <h3 className="mt-4">{t('privacyPolicy.infoCollection.title')}</h3>
      <p>{t('privacyPolicy.infoCollection.description')}</p>

      <h3 className="mt-4">{t('privacyPolicy.logData.title')}</h3>
      <p>{t('privacyPolicy.logData.description')}</p>

      <h3 className="mt-4">{t('privacyPolicy.cookies.title')}</h3>
      <p>{t('privacyPolicy.cookies.description')}</p>
      <p>{t('privacyPolicy.cookies.management')}</p>

      <h3 className="mt-4">{t('privacyPolicy.dataProtection.title')}</h3>
      <p>{t('privacyPolicy.dataProtection.description')}</p>

      <h3 className="mt-4">{t('privacyPolicy.serviceProviders.title')}</h3>
      <p>{t('privacyPolicy.serviceProviders.description')}</p>

      <h3 className="mt-4">{t('privacyPolicy.security.title')}</h3>
      <p>{t('privacyPolicy.security.description')}</p>

      <h3 className="mt-4">{t('privacyPolicy.childrenPrivacy.title')}</h3>
      <p>{t('privacyPolicy.childrenPrivacy.description')}</p>

      <h3 className="mt-4">{t('privacyPolicy.changes.title')}</h3>
      <p>{t('privacyPolicy.changes.description')}</p>

      <h3 className="mt-4">{t('privacyPolicy.contactUs.title')}</h3>
      <p>{t('privacyPolicy.contactUs.description')}</p>
    </div>
  );
};

export default PrivacyPolicy;
