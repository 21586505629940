import React, { useState, useRef, useEffect } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import './MainBanner.css';
import { blogData } from '../data/blogData'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';  // i18n import

const LazyLoadImage = ({ src, alt, className }) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        imgRef.current.src = src;  // Görünüm alanına girdiğinde resmi yükle
        observer.unobserve(imgRef.current);  // Yüklendikten sonra observer'ı durdur
      }
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [src]);

  return <img ref={imgRef} alt={alt} className={className} />;
};

const HSTComponent = () => {
  const { t, i18n } = useTranslation();  // Hook for translations
  const currentLang = i18n.language;  // Aktif dil
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Dil desteğine uygun carousel item'lar ve article preview'lar
  const carouselItems = blogData.slice(0, 8); 
  const articlePreviews = blogData.slice(-3); 

  const truncateSummary = (summary, wordLimit = 30) => {
    if (!summary || typeof summary !== 'string') {
      return '';  // Eğer boşsa döndürmeyin
    }

    const words = summary.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return summary;
  };

  return (
    <Container className="hst-component">
      <Row>
        <Col md={8} className="carousel-container mt-5">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            {carouselItems.map((item) => (
              <Carousel.Item key={item.id}>
                <a
                  href={`/blog/${item.slug}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <LazyLoadImage
                    src={item.image}
                    alt={item.title[currentLang]}  // Aktif dildeki başlık
                    className="d-block w-100"
                  />
                </a>
                <Carousel.Caption>
                  <h3>{item.title[currentLang]}</h3>  {/* Dil için title */}
                  <p>{truncateSummary(item.summary[currentLang], 30)}</p>  {/* Dil için summary */}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col md={4} className="side-content text-start">
          {articlePreviews.map((article) => (
            <div key={article.id} className="article-preview mb-4">
              <LazyLoadImage
                src={article.image}
                alt={article.title[currentLang]}
                className="mb-2 img-fluid"
              />
              <div>
                <a 
                  href={`/blog/${article.slug}`} 
                  style={{ textDecoration: 'none', color: 'inherit', display: 'block', fontWeight: 'bold' }}
                >
                  {article.title[currentLang]}  {/* Dil için başlık */}
                </a>
                <p style={{ marginTop: '5px' }}>
                  {truncateSummary(article.summary[currentLang], 30)}  {/* Dil için summary */}
                  <br />
                  <a
                    href={`/blog/${article.slug}`} 
                    style={{ textDecoration: 'none', color: '#e53935' }}
                  >
                    {t('carousel.read_more')} <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                </p>
              </div>
            </div>
          ))}
        </Col>
      </Row>
      <Row className="pagination-row">
        <Col>
          <div className="pagination-container">
            {carouselItems.map((_, i) => (
              <div
                key={i}
                className={`pagination-item ${index === i ? 'active' : ''}`}
                onClick={() => handleSelect(i)}
              >
                {i + 1}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HSTComponent;
