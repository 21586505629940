import React from 'react'
import AboutContent from '../components/AboutContent'
import SubsEmagazine from '../components/SubsEmagazine'
const About = () => {
  return (
    <div>
      <AboutContent />
      <SubsEmagazine />
    </div>
  )
}

export default About