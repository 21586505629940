import React from 'react';
import { useTranslation } from 'react-i18next'; // i18n import

const KvkkPolicy = () => {
  const { t } = useTranslation(); // i18n hook

  return (
    <div>
      <p className="mt-5 mb-5 text-muted fs-7 container text-start">
        <strong>{t('kvkk.title')}</strong>
        <br /><br />
        {t('kvkk.intro')}
        <br /><br />
        <strong>{t('kvkk.purposeTitle')}</strong>
        <br /><br />
        {t('kvkk.purpose')}
        <br /><br />
        <strong>{t('kvkk.transferTitle')}</strong>
        <br /><br />
        {t('kvkk.transfer')}
        <br /><br />
        <strong>{t('kvkk.collectionTitle')}</strong>
        <br /><br />
        {t('kvkk.collection')}
        <br /><br />
        <strong>{t('kvkk.rightsTitle')}</strong>
        <br /><br />
        <ul>
          <li>{t('kvkk.rights.learn')}</li>
          <li>{t('kvkk.rights.requestInfo')}</li>
          <li>{t('kvkk.rights.purpose')}</li>
          <li>{t('kvkk.rights.thirdParties')}</li>
          <li>{t('kvkk.rights.correct')}</li>
          <li>{t('kvkk.rights.delete')}</li>
          <li>{t('kvkk.rights.notifyThirdParties')}</li>
          <li>{t('kvkk.rights.objectToOutcome')}</li>
          <li>{t('kvkk.rights.compensation')}</li>
        </ul>
        <br /><br />
        <strong>{t('kvkk.exerciseRightsTitle')}</strong>
        <br /><br />
        {t('kvkk.exerciseRights')}
        <br /><br />
        <strong>{t('kvkk.applicationMethodsTitle')}</strong>
        <br /><br />
        <ul>
          <li>{t('kvkk.applicationMethods.email')}</li>
          <li>{t('kvkk.applicationMethods.notary')}</li>
          <li>{t('kvkk.applicationMethods.keo')}</li>
        </ul>
        <br /><br />
        <strong>{t('kvkk.evaluationProcessTitle')}</strong>
        <br /><br />
        {t('kvkk.evaluationProcess')}
        <br /><br />
        <strong>{t('kvkk.complaintTitle')}</strong>
        <br /><br />
        {t('kvkk.complaint')}
      </p>
    </div>
  );
};

export default KvkkPolicy;
