import React, { useEffect, useRef, memo } from 'react';
import './TradingViewCryptoWidget.css';

function TradingViewCryptoWidget() {
  const container = useRef(null);
  const scriptLoadedRef = useRef(false); // Yüklenmiş script'i izlemek için referans

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !scriptLoadedRef.current) {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbols: [
            { description: "Bitcoin", proName: "CME:BTC1!" },
            { description: "Ethereum", proName: "CME:ETH1!" },
            { description: "Tether", proName: "BINANCE:BTCUSDT" },
            { description: "BNB", proName: "CRYPTOCAP:BNB" },
            { description: "Solana", proName: "CRYPTOCAP:SOL" }
          ],
          showSymbolLogo: true,
          isTransparent: false,
          displayMode: "adaptive",
          colorTheme: "dark",
          locale: "en",
          width: "100%"
        });
        container.current.appendChild(script);
        scriptLoadedRef.current = true;
      }
    });

    if (container.current) {
      observer.observe(container.current);
    }

    return () => {
      if (container.current) {
        observer.unobserve(container.current);
        while (container.current.firstChild) {
          container.current.removeChild(container.current.firstChild);
        }
        scriptLoadedRef.current = false; // Widget kaldırıldığında flag'i sıfırla
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

export default memo(TradingViewCryptoWidget);
