import React from 'react';
import { useParams } from 'react-router-dom';
import getCategoryContentData from '../data/getCategoryContentData';
import { Container, Row, Col, Card } from 'react-bootstrap';
import BlogSection from './BlogSection';
import './ContentPage.css';
import { useTranslation } from 'react-i18next';

const ContentPage = () => {
  const { t, i18n } = useTranslation(); // i18n'den çeviri fonksiyonunu alıyoruz
  const { category, slug } = useParams(); // category ve slug parametrelerini alıyoruz
  const currentLanguage = i18n.language; // Mevcut dili alıyoruz

  // Kategoriyi ve içeriği slug ile eşleştiriyoruz (slug çevirisiz)
  const categoryData = getCategoryContentData[category];
  const content = categoryData?.cards.find(card => card.slug === slug);

  if (!content) {
    return (
      <Container className="mt-5">
        <p>{t('contentPage.contentNotFound')}</p>
      </Container>
    );
  }

  return (
    <Container className="content-page mt-5">
      <Row className="justify-content-center mb-5">
        <Col md={12}>
          <Card>
            <Card.Img
              variant="top"
              className="content-image-category"
              src={content.image}
              alt={content.title[currentLanguage]} // Dil desteği
            />
            <Card.Body>
              <Card.Title>{content.title[currentLanguage]}</Card.Title>
              <Card.Text className="mb-4 text-muted">
                {content.summary[currentLanguage]}
              </Card.Text>
              {content.content[currentLanguage].map((paragraph, index) => (
                <Card.Text key={index} className="mb-4 text-start">
                  {paragraph}
                </Card.Text>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <BlogSection />
    </Container>
  );
};

export default ContentPage;
