// src/data/aboutContentData.js

const aboutContentData = {
    title: "Fikir Paneli",
    paragraphs: [
      "Bilgiye açılan kapınız.",
      "Kültür-sanat, bilim-teknoloji, sosyoloji, psikoloji, turizm ve sağlık, kişisel gelişim, araştırma, ekonomi, fintech ve finans gibi geniş bir yelpazede; güncel ve derinlemesine içerikler sunarak sizlere dünyayı keşfetme imkanı sunuyoruz. Dergimiz ve YouTube kanalımız aracılığıyla hayatın her alanında ilham verici ve düşündürücü konuları ele alarak bilgiye kolay erişim sağlamayı amaçlıyoruz.",
      "Amacımız, okuyucularımıza ve izleyicilerimize sürekli olarak yenilikçi, özgün ve kaliteli içerikler sunmak, dünyaya farklı bir perspektiften bakmalarına yardımcı olmaktır. Fikir Paneli, bilgiye dair merakınızı giderecek, ufkunuzu genişletecek ve sizi sürekli olarak keşfetmeye teşvik edecek bir platformdur.",
      "Bilginin ve öğrenmenin sınırlarını zorlayan bu yolculukta bize katılın, fikirlerinizi genişletin, dünyayı yeniden keşfedin!"  
    ]
  };
  
  export default aboutContentData;
  