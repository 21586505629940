import React from 'react';
import { useParams } from 'react-router-dom';
import getCategoryContentData from '../data/getCategoryContentData';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // i18n ekleniyor

const DynamicPage = () => {
  const { t, i18n } = useTranslation(); // i18n çeviri fonksiyonu
  const { category } = useParams();
  const currentLanguage = i18n.language; // Mevcut dili alıyoruz
  const categoryData = getCategoryContentData[category]; // Kategoriyi alıyoruz

  if (!categoryData) {
    return <p>{t('dynamicPage.contentNotFound')}</p>;
  }

  return (
    <Container className="dynamic-page mt-5">
      <h1 className="text-center mb-4">
        {categoryData.title[currentLanguage]} {/* Dil desteği */}
      </h1>
      <Row className="cards-container mb-3 mt-5">
        {categoryData.cards.map((card) => (
          <Col key={card.id} md={4} sm={6} className="mb-4">
            <Card className="h-100">
              <Card.Img variant="top" src={card.image} alt={card.title[currentLanguage]} />
              <Card.Body>
                <Card.Title>{card.title[currentLanguage]}</Card.Title> {/* Dil desteği */}
                <Card.Text>{card.summary[currentLanguage]}</Card.Text> {/* Dil desteği */}
                {/* Slug ile linkleme */}
                <a href={`/${category}/${card.slug}`} className="btn btn-primary">
                  {t('dynamicPage.viewContent')}
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default DynamicPage;
