import coursedumuguet from "../assets/news/coursedemuguet-fikirpaneli.webp";
import coursedemuguet2 from "../assets/news/coursedemuguet-2-fikirpaneli.webp";
import mobilfest from "../assets/news/mobilefest-fikirpaneli.webp"
import spor from "../assets/news/futbol-fikirpaneli.webp";
const newsData = [
    {
        id: 1,
        title: "FRANSA’DAKİ COURSE DU MUGUET YARIŞI BU YIL DA COŞKUYLA KARŞILANDI",
        image: coursedumuguet,
        content: [
            "Course du Muguet yarışı bu yıl da yüzlerce koşucu ve yürüyüşçüyü bir araya getirdi. Yamaha Bisiklet Turu Derneği tarafından düzenlenen yol yarışında katılımcı sayısı sınırsızdı. Yol sporlarına meraklı olan herkesin katılabileceği yarış renkli sahnelere ev sahipliği yaptı.",
            "Koşucu, bisikletçi ve yürüyüşler mayıs ayını bu yarışla coşkuyla karşıladı. Yarı kentsel, yarı ormanlık bir ortamda gerçekleştirilen etkinlikte 10 km’lik parkur tek bir döngüde yapıldı. İlk kez aynı gün içerisinde herkese açık 5 km’lik parkur da sunuldu."
        ]
    },
    {
        id: 2,
        title: "Teknolojinin Kalbi Mobilefest Fuarında, Fuarın Kalbi HST Mobil’de Attı",
        image:  mobilfest,
        content: [
            "HST Mobil; sektörün diğer önemli şirketleriyle yeni ağlar oluşturmak, ziyaretçilerini ürünleriyle bir araya getirmek için Mobilefest Fuarındaydı.",
            "İstanbul Kongre Merkezinde, Mobilefest Fuarına teknoloji ve mobil ödeme sektörlerinin önemli şirketleri katılım sağladı. HST Mobil de bu önemli etkinlikteki yerini aldı, teknoloji meraklısı ziyaretçilerle ve sektörün diğer kilometre taşlarıyla bir araya geldi.",
            "Sektör liderleri ve etkileyicileriyle ağ oluşturmak iddiasıyla hayata geçirilen fuar, yurt içinden ve yurt dışından önemli isimlerin konuşmalarına sahne oldu. Bunun yanında her bir şirket için kendini tanıtma, yeni teknolojileri aktarma fırsatı sunan fuar, üç günlük bir periyotta gerçekleşti. Önemli etkinlikler ve oturumlarla teknoloji meraklılarına yeni deneyimler yaşattı. İletişim ve bağlantıya odaklanan Mobilefest Fuarı marka farkındalığı yaratmak ve şirketlerin ürünlerini sergilemeleri için önemli bir altyapı oluşturdu.",
            "Çeşitli etkinliklerle dolu dolu bir fuara tanık olan ziyaretçiler, şirketlerin temsilcileriyle doğrudan muhatap olma fırsatı yakaladı. Özellikle üniversite öğrencilerinin ilgiyle karşıladığı fuar, bu sektöre yönelik fuar ihtiyacını gözler önüne serdi.",
            "Vodafone, Türk Telekom, Turkcell gibi teknoloji devlerinin yanı sıra Papara, Paratika, Aktifbank, DT Cloud, Paybyme gibi yeni nesil ödeme çözümlerinin önemli şirketleri de fuardaydı. Fuar boyunca ziyaretçiler, en son teknolojilere yerinde tanıklık ederken sektörle ilgili bilgileri sahiplerinden edindi.",
            "HST Mobil de en son teknoloji ödeme çözümlerini ziyaretçilerine tanıttı. Fuar boyunca oluşturulan yeni ağların, paylaşılan teknolojilerin ödeme teknolojileri alanında çok önemli bir yere sahip olduğu açık. Yepyeni fuarlarda da mobil ödeme teknolojilerindeki ağın genişletilmesi, sektörün paydaşlarıyla Türkiye’deki ödeme sistemlerinin gelişimine katkı sunulmasını dileriz."
        ]
    },
    {
        id: 3,
        title: "HST Holding Şirketinde Unutulmaz Bir Spor Etkinliği!",
        image: spor,
        content: [
            "Geçtiğimiz hafta HST Holding şirketimizde düzenlenen karma halı saha maçı, iş arkadaşlarımız ve şirket CEO’muz Emrullah Bey'in bir arada olduğu unutulmaz anlara sahne oldu. Bu etkinlikte, rekabetin yanı sıra dostluk ve eğlence de zirve yaptı.",
            "Maç, başlama düdüğüyle birlikte hızlı bir tempoya girdi. Her iki takım da sahada taktiksel yeteneklerini sergilerken, pozisyonlar golle sonuçlanmaya başladı. HST Holding çalışanları arasında yaşanan bu karşılaşmada, her iki takım da kendilerinden maksimum performansı sergilemeye odaklandı.",
            "HST Holding takımındaki oyuncuların organizasyonlarıyla dikkatleri üzerlerine çektiği maçta, skor zaman zaman lehlerine döndü. Ancak AS4 takımı da pes etmedi ve sahadaki mücadelesini sürdürdü. Birlikte geçirdiğimiz bu eğlenceli zaman dilimi, skordan öte dostluğun ve iş birliğinin değerini bir kez daha gösterdi.",
            "Maç boyunca saha içinde heyecanı yakalamak için objektifini titizlikle ayarlayan Burhan Akdağ, anıların ölümsüzleştirilmesinde büyük rol oynadı. Her pozisyon, her gol, maçın verdiği heyecanı en ince ayrıntısına kadar yansıttı.",
            "Son düdük çaldığında skor tablosunda AS4 takımının lehine bir fark olsa da asıl kazananın spor ve birlik olduğunu net bir şekilde görebiliyorduk. Bu tür etkinlikler, iş ortamında bir araya gelme ve birlikte zaman geçirme fırsatı sunarak şirket içi iletişimi güçlendiriyor.",
            "Bu unutulmaz etkinliğin ardından, her iki takım da bir araya gelerek benzer organizasyonların devamını getirme kararı aldı. HST Holding çalışanları olarak, sporun ve birlikte geçirilen zamanın önemini bir kez daha vurguladığımız bu etkinlik, iş yerimizdeki dayanışmanın ve dostluğun ne kadar değerli olduğunu bir kez daha kanıtladı."
        ]
    },

    {
        id: 4,
        title: "HST HOLDİNG DE YARIŞTAKİ YERİNİ ALDI",
        image: coursedemuguet2,
        content: [
            "Cergy ve Pontoise yollarında yapılan yarışa iş dünyasından da pek çok isim katılım sağladı. HST Holding de yarışa katılan şirketler arasındaki yerini aldı. Holding’in Yönetim Kurulu Başkan Yardımcısı Ferhat Bayhan yarışa katıldıkları için mutluluk duyduklarını belirtti.",
            "Sürdürülebilir bir dünya için adım atan koşucu ve yürüyüşçü iş insanları için yeni ağların oluşturulmasında önemli bir yer edindi etkinlik.",
            "Etkinlik neredeyse her yıl düzenleniyor. Her yıl başka bir kurum ya da kuruluşun önderliğinde düzenlenen etkinlik insan, doğa ve yaşam alanlarının uyumunu sergiliyor. Dünyanın pek çok yerinden koşucu ve yürüyüşçülerle her yıl renkli sahnelere ev sahipliği yapıyor.",
            "Etkinliğe katılan ve izleyenler için farkındalık da yaratan etkinlik daha pek çok insana yıllarca ev sahipliği yapacak gibi."
        ]
    },
];

export default newsData;
