import React from 'react';
import { useParams } from 'react-router-dom';
import newsData from '../data/newsData';
import { blogData } from '../data/blogData'; // blogData import edildi
import './NewsDynamic.css'; // CSS file for custom styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import BlogSection from './BlogSection';
import { useTranslation } from 'react-i18next'; // i18n import

const NewsDynamic = () => {
    const { t, i18n } = useTranslation(); // Hook for translations
    const { id } = useParams();
    const currentLang = i18n.language; // Aktif dil
    const newsItem = newsData.find((news) => news.id === parseInt(id));

    if (!newsItem) {
        return <div>{t('newsDynamic.news_not_found')}</div>; // Çeviri için kullanıldı
    }

    const mostReadToShow = blogData.slice(0, 4);

    return (
        <div className="news-dynamic-container container">
            <div className="row">
            <div className="col-lg-8">
                    <h2 className="news-dynamic-title">{newsItem.title}</h2>
                    <img 
                        src={newsItem.image} 
                        alt={newsItem.title} 
                        className="news-dynamic-image" 
                    />
                    <div className="news-dynamic-content">
                        {newsItem.content.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="most-read-container">
                        <h5 className="mb-3">{t('newsDynamic.most_read')}</h5> {/* Çeviri kullanıldı */}
                        {mostReadToShow.map((item, index) => (
                            <div key={index} className="most-read-item mb-3">
                                <img src={item.image} alt={item.title[currentLang]} className="img-fluid" />  {/* Dil desteği ile başlık */}
                                <div>
                                    <h6 className='text-start'>{item.title[currentLang]}</h6>  {/* Dil desteği ile başlık */}
                                    <p className='text-muted text-start'>{item.summary[currentLang]}  {/* Dil desteği ile summary */}
                                    <br></br>
                                    <a
                                        href={`/blog/${item.slug}`} // Slug değerini kullanarak dinamik link oluşturuyoruz
                                        style={{ textDecoration: 'none', color: '#e53935' }}
                                        className='text-start read-more-most-read'
                                    >
                                        {t('newsDynamic.read_more')} <FontAwesomeIcon icon={faArrowRight} />
                                    </a>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <BlogSection />
        </div>
    );
};

export default NewsDynamic;
