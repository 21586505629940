import React from 'react';
import { useTranslation } from 'react-i18next'; // i18n import

const CookiePolicy = () => {
  const { t } = useTranslation(); // i18n çeviri fonksiyonu

  return (
    <div>
      <p className="mt-5 mb-5 text-muted fs-7 container text-start">
        <strong>{t('cookiePolicy.title')}</strong>
        <br /><br />
        {t('cookiePolicy.intro')}
        <br /><br />
        <strong>{t('cookiePolicy.whatAreCookies.title')}</strong>
        <br /><br />
        {t('cookiePolicy.whatAreCookies.description')}
        <br /><br />
        <strong>{t('cookiePolicy.whyCookies.title')}</strong>
        <br /><br />
        {t('cookiePolicy.whyCookies.description')}
        <br /><br />
        <strong>{t('cookiePolicy.types.title')}</strong>
        <br /><br />
        <ul>
          <li><strong>{t('cookiePolicy.types.session.title')}</strong> {t('cookiePolicy.types.session.description')}</li>
          <li><strong>{t('cookiePolicy.types.performance.title')}</strong> {t('cookiePolicy.types.performance.description')}</li>
          <li><strong>{t('cookiePolicy.types.functional.title')}</strong> {t('cookiePolicy.types.functional.description')}</li>
          <li><strong>{t('cookiePolicy.types.thirdParty.title')}</strong> {t('cookiePolicy.types.thirdParty.description')}</li>
        </ul>
        <br /><br />
        <strong>{t('cookiePolicy.usage.title')}</strong>
        <br /><br />
        <ul>
          <li><strong>{t('cookiePolicy.usage.security.title')}</strong> {t('cookiePolicy.usage.security.description')}</li>
          <li><strong>{t('cookiePolicy.usage.functionality.title')}</strong> {t('cookiePolicy.usage.functionality.description')}</li>
          <li><strong>{t('cookiePolicy.usage.performance.title')}</strong> {t('cookiePolicy.usage.performance.description')}</li>
          <li><strong>{t('cookiePolicy.usage.advertising.title')}</strong> {t('cookiePolicy.usage.advertising.description')}</li>
        </ul>
        <br /><br />
        <strong>{t('cookiePolicy.control.title')}</strong>
        <br /><br />
        {t('cookiePolicy.control.description')}
        <br /><br />
        <strong>{t('cookiePolicy.consent.title')}</strong>
        <br /><br />
        {t('cookiePolicy.consent.description')}
      </p>
    </div>
  );
}

export default CookiePolicy;
