import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import tr from "./locales/tr.json";
import fr from "./locales/fr.json";
import spa from "./locales/spa.json";
import ger from "./locales/ger.json";
import ar from "./locales/ar.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: { translation: en },
  tr: { translation: tr },
  fr: { translation: fr },
  spa: { translation: spa },
  ger: { translation: ger },
  ar: { translation: ar },
};

i18n
  .use(LanguageDetector) // Detects language settings in browser, localStorage, etc.
  .use(initReactI18next)  // Connects i18next with React
  .init({
    resources, // Translations
    fallbackLng: "en", // Fallback language if the user's language is not available
    debug: true, // Enable debug for troubleshooting
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator'], // How language should be detected
      caches: ['localStorage', 'cookie'] // Where to store the detected language
    }
  });

export default i18n;
