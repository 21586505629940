import React from 'react';
import FikirPaneliMagazine from '../components/FikirPaneliMagazine';

const FikirPaneli = () => {
  return (
    <div>
      <FikirPaneliMagazine />
    </div>
  );
}

export default FikirPaneli;
