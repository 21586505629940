import React, { useState, useEffect } from 'react';
import './CookieConsent.css';
import { useTranslation } from 'react-i18next'; 
import 'bootstrap/dist/css/bootstrap.min.css';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(); 

  useEffect(() => {
    const consent = sessionStorage.getItem('cookieConsent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    sessionStorage.setItem('cookieConsent', 'accepted');
    setShowConsent(false);
    setShowModal(false);
  };

  const handleReject = () => {
    sessionStorage.setItem('cookieConsent', 'rejected');
    clearCookies();
    setShowConsent(false);
  };

  const clearCookies = () => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  };

  const handleSettings = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (!showConsent) return null;

  return (
    <>
      {!showModal && (
        <div className="cookie-consent">
          <div className="cookie-consent-content">
            <p>{t('cookieConsent.description')}</p>
            <div className="cookie-consent-buttons">
              <button className="btn btn-primary" onClick={handleReject}>
                {t('cookieConsent.rejectAll')}
              </button>
              <button className="btn btn-secondary" onClick={handleSettings}>
                {t('cookieConsent.settings')}
              </button>
              <button className="btn btn-primary" onClick={handleAccept}>
                {t('cookieConsent.acceptAll')}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content-cookie">
            <div className="modal-header">
              <h5 className="modal-title">{t('cookieSettings.title')}</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">
              <p>{t('cookieSettings.description')}</p>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="necessary-cookies"
                />
                <label className="form-check-label" htmlFor="necessary-cookies">
                  {t('cookieSettings.necessary')}
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="functional-cookies"
                />
                <label className="form-check-label" htmlFor="functional-cookies">
                  {t('cookieSettings.functional')}
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="performance-cookies"
                />
                <label className="form-check-label" htmlFor="performance-cookies">
                  {t('cookieSettings.performance')}
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="advertising-cookies"
                />
                <label className="form-check-label" htmlFor="advertising-cookies">
                  {t('cookieSettings.advertising')}
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                {t('cookieSettings.close')}
              </button>
              <button type="button" className="btn btn-primary" onClick={handleAccept}>
                {t('cookieSettings.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieConsent;
