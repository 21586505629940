import React, { useEffect, useRef, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './FeaturedVideos.css';
import { useTranslation } from 'react-i18next'; // i18next importu

const LazyLoadIframe = ({ videoUrl }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        iframeRef.current.src = videoUrl; // Iframe'in src'sini görünüm alanına girdiğinde yüklüyoruz
        observer.unobserve(iframeRef.current); // Yüklendikten sonra observer'ı durduruyoruz
      }
    });

    if (iframeRef.current) {
      observer.observe(iframeRef.current); // Observer başlatıyoruz
    }

    return () => {
      if (iframeRef.current) {
        observer.unobserve(iframeRef.current); // Temizlik işlemi
      }
    };
  }, [videoUrl]);

  return (
    <iframe
      ref={iframeRef}
      width="100%"
      height="180"
      title="Lazy Loaded Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

const FeaturedVideos = () => {
  const { t } = useTranslation(); // i18n çeviri fonksiyonu
  const [activeTab, setActiveTab] = useState(t('videos.all')); // Varsayılan sekme

  const TABS = [
    {
      name: t("videos.all"),
      videos: [
        "https://www.youtube.com/embed/MVVCfnJwryE",
        "https://www.youtube.com/embed/1iJ6cUqnlm0",
        "https://www.youtube.com/embed/jMvFS_JOGlQ"
      ]
    },
    {
      name: t("videos.cultureAndArt"),
      videos: [
        "https://www.youtube.com/embed/wP_DuFZaYqQ",
        "https://www.youtube.com/embed/7EhofMQfNJc",
        "https://www.youtube.com/embed/vCNojlJX8xU"
      ]
    },
    {
      name: t("videos.sports"),
      videos: [
        "https://www.youtube.com/embed/jMvFS_JOGlQ",
        "https://www.youtube.com/embed/epfdKE-ZcqU",
        "https://www.youtube.com/embed/X-cEFeX30cI"
      ]
    },
    {
      name: t("videos.scienceAndTech"),
      videos: [
        "https://www.youtube.com/embed/j0iJ6R_6xk8",
        "https://www.youtube.com/embed/fmQQvSuBlfo",
        "https://www.youtube.com/embed/EGIuBzT74dY"
      ]
    }
  ];

  return (
    <div className="container featured-videos-container" style={{ overflow: 'hidden' }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={activeTab}
        onSelect={(tab) => setActiveTab(tab)}
        className="mb-3"
      >
        {TABS.map((tab) => (
          <Tab eventKey={tab.name} title={tab.name} key={tab.name}>
            <div className="row mb-3 mt-3">
              <div className="col-12">
                <p className="text-bold text-start">{tab.name}</p>
                <p style={{ color: '#919191', textAlign: 'left' }}>
                  {t("videos.description")}
                </p>
              </div>
            </div>
            <div className="row">
              {tab.videos.map((videoUrl, index) => (
                <div key={index} className="col-md-4 mb-4">
                  <LazyLoadIframe videoUrl={videoUrl} />
                </div>
              ))}
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

export default FeaturedVideos;
