import React from 'react'
import CookiePolicyComponent from '../components/CookiePolicy.js'
const CookiePolicy = () => {
  return (
    <div>
        <CookiePolicyComponent />
    </div>
  )
}

export default CookiePolicy