import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { blogData } from '../data/blogData';
import { useTranslation } from 'react-i18next'; // i18n kullanımı

const Breadcrumb = () => {
  const location = useLocation();
  const { slug } = useParams();
  const { t, i18n } = useTranslation(); // i18n için çeviri kancası
  const currentLang = i18n.language; // Aktif dili alıyoruz
  const pathnames = location.pathname.split('/').filter(x => x);

  const getTitle = (slug) => {
    const blog = blogData.find(blog => blog.slug === slug);
    return blog ? blog.title[currentLang] || blog.title['en'] : t('breadcrumb.unknown'); // Aktif dilde başlık, yoksa İngilizce
  };

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">{t('breadcrumb.home')}</Link> {/* Anasayfa çevirisi */}
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const label = value === slug ? getTitle(slug) : t(`breadcrumb.${value}`, value.charAt(0).toUpperCase() + value.slice(1));

          return (
            <li key={to} className={`breadcrumb-item ${isLast ? 'active' : ''}`} aria-current={isLast ? 'page' : undefined}>
              {isLast ? label : <Link to={to}>{label}</Link>}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
