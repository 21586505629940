import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './SubsEmagazine.css';
import { useTranslation } from 'react-i18next'; // i18n import

const SubsEmagazine = () => {
  const { t } = useTranslation(); // Hook for translations
  const [showPopup, setShowPopup] = useState(false);

  const [formData, setFormData] = useState({
    host: `${process.env.REACT_APP_MAIL_SEND_HOST_DATA}`,
    fullName: "",
    email: "",
    subject: "",
    message: "",
});
const [responseMessage, setResponseMessage] = useState("");
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};
const [captchaValue, setCaptchaValue] = useState(null);

const onCaptchaChange = (value) => {
  setCaptchaValue(value);
};
const handleSubmit = async (e) => {
  e.preventDefault();

  if (!captchaValue) {
    setResponseMessage("Please complete the CAPTCHA.");
    setShowPopup(true);
    return;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_MAIL_HOST}:${process.env.REACT_APP_MAIL_PORT}/api/send`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, captcha: captchaValue }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.text();
    setResponseMessage(data);
    setShowPopup(true);
  } catch (error) {
    setResponseMessage("There was a problem with the form submission.");
    setShowPopup(true);
    console.error("Error:", error);
  }
};
  return (
    <div className="subscription-container">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className='text-start'>{t('subsEmagazine.title')}</h2>
            <p className='text-start'>
              {t('subsEmagazine.description')}
            </p>
          </div>
          <div className="col-md-6">
            <form className="subscription-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <input
                    onchance={handleChange} 
                    type="text" 
                    className="form-control" 
                    placeholder={t('subsEmagazine.name_placeholder')} 
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <input
                    onchance={handleChange} 
                    type="text" 
                    className="form-control" 
                    placeholder={t('subsEmagazine.subject_placeholder')} 
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <input
                    onchance={handleChange} 
                    type="text" 
                    className="form-control" 
                    placeholder={t('subsEmagazine.phone_placeholder')} 
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <input
                    onchance={handleChange} 
                    type="email" 
                    className="form-control" 
                    placeholder={t('subsEmagazine.email_placeholder')} 
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <textarea 
                    onchance={handleChange}
                    className="form-control" 
                    placeholder={t('subsEmagazine.message_placeholder')} 
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-right">
                  <ReCAPTCHA
                    sitekey="6LebXCcqAAAAAAM5YcEdxiikEnu--gQUZU1elqIL"
                    onChange={onCaptchaChange}
                  />
                  <button type="submit" className="btn btn-danger">
                    {t('subsEmagazine.submit_button')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubsEmagazine;
