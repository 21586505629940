import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { blogData } from '../data/blogData'; // blogData.js dosyasından veri çekiyoruz
import './YoutubeChannel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'; // i18n import

const LazyLoadIframe = ({ videoUrl }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        iframeRef.current.src = videoUrl; // Iframe'in src'sini görünüm alanına girdiğinde yüklüyoruz
        observer.unobserve(iframeRef.current); // Yüklendikten sonra observer'ı durduruyoruz
      }
    });

    if (iframeRef.current) {
      observer.observe(iframeRef.current); // Observer başlatıyoruz
    }

    return () => {
      if (iframeRef.current) {
        observer.unobserve(iframeRef.current); // Temizlik işlemi
      }
    };
  }, [videoUrl]);

  return (
    <iframe
      ref={iframeRef}
      width="100%"
      height="180"
      title="Lazy Loaded Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{ borderRadius: '10px' }}
    ></iframe>
  );
};

const YouTubeChannel = () => {
  const { t, i18n } = useTranslation(); // Hook for translations
  const currentLang = i18n.language; // Aktif dil

  const firstRowVideos = [
    "https://www.youtube.com/embed/MVVCfnJwryE",
    "https://www.youtube.com/embed/sQb1YbaczVQ",
    "https://www.youtube.com/embed/EGIuBzT74dY"
  ];

  const secondRowVideos = [
    "https://www.youtube.com/embed/1iJ6cUqnlm0",
    "https://www.youtube.com/embed/zgEtepYd-ms",
    "https://www.youtube.com/embed/YjFfR7hg0hI"
  ];

  const thirdRowVideos = [
    "https://www.youtube.com/embed/iodpGKXWmS0",
    "https://www.youtube.com/embed/po3U5WiQfd4",
    "https://www.youtube.com/embed/oJwEgfz_-m4"
  ];

  const mostReadToShow = blogData.slice(0, 3); // blogData'dan veri çekiyoruz

  return (
    <div className="container youtube-channel-container">
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="most-read-container">
            <h5 className="mb-3">{t('YoutubeChannel.most_read')}</h5>
            {mostReadToShow.map((item, index) => (
              <div key={index} className="most-read-item mb-3">
                <img src={item.image} alt={item.title[currentLang]} className="img-fluid" />
                <div>
                  <h6 className='text-start'>{item.title[currentLang]}</h6>
                  <p className='text-muted text-start'>{item.summary[currentLang]}
                    <br></br>
                    <a
                      href={`/blog/${item.slug}`} // Slug değerini kullanarak dinamik link oluşturuyoruz
                      style={{ textDecoration: 'none', color: '#e53935' }}
                      className='text-start read-more-most-read'
                    >
                      {t('YoutubeChannel.read_more')} <FontAwesomeIcon icon={faArrowRight} />
                    </a>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-12">
          {/* İlk Satır Videoları */}
          <div className="row">
            <div className="col-12 mb-3">
              <p style={{ textAlign: 'left', fontWeight: 'bold' }}> {t('YoutubeChannel.technology')} </p>
              <p style={{ textAlign: 'left', color: '#919191', fontSize: '12px' }}> {t('YoutubeChannel.technology_description')} </p>
            </div>
            {firstRowVideos.map((videoUrl, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4 video-item">
                <LazyLoadIframe videoUrl={videoUrl} />
              </div>
            ))}
          </div>

          {/* İkinci Satır Videoları */}
          <div className="row">
            <div className="col-12 mb-3">
              <p style={{ textAlign: 'left', fontWeight: 'bold' }}> {t('YoutubeChannel.fikirpaneli')} </p>
              <p style={{ textAlign: 'left', color: '#919191', fontSize: '12px' }}> {t('YoutubeChannel.fikirpaneli_description')}</p>
            </div>
            {secondRowVideos.map((videoUrl, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4 video-item">
                <LazyLoadIframe videoUrl={videoUrl} />
              </div>
            ))}
          </div>

          {/* Üçüncü Satır Videoları */}
          <div className="row">
            <div className="col-12 mb-3">
              <p style={{ textAlign: 'left', fontWeight: 'bold' }}> {t('YoutubeChannel.third_row')} </p>
              <p style={{ textAlign: 'left', color: '#919191', fontSize: '12px' }}> {t('YoutubeChannel.third_row_description')}</p>
            </div>
            {thirdRowVideos.map((videoUrl, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4 video-item">
                <LazyLoadIframe videoUrl={videoUrl} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouTubeChannel;
