import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // i18n import
import './SubscribeForm.css';

const SubscribeForm = () => {
  const { t } = useTranslation(); // Hook for translations
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (email && name && surname && phone) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [email, name, surname, phone]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    if (e.target.value.length <= 40) {
      setName(e.target.value);
    }
  };

  const handleSurnameChange = (e) => {
    if (e.target.value.length <= 40) {
      setSurname(e.target.value);
    }
  };

  const handlePhoneChange = (e) => {
    if (e.target.value.length <= 30) {
      setPhone(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submit işlemleri burada yapılacak
  };

  return (
    <div className="subscribe-container container my-5">
      <div className="text-center">
        <i className="fas fa-user-circle fa-3x subscribe-icon"></i>
        <h2 className="my-3 subscribe-title">{t('subscribeForm.title')}</h2>
        <p className="subscribe-description">{t('subscribeForm.description')}</p>
      </div>
      
      <hr className="my-4 subscribe-divider" />

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formName" className="mb-5">
              <Form.Label>{t('subscribeForm.first_name')}</Form.Label>
              <Form.Control 
                type="text" 
                placeholder={t('subscribeForm.first_name')} 
                value={name}
                onChange={handleNameChange}
                maxLength="40"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formSurname">
              <Form.Label>{t('subscribeForm.last_name')}</Form.Label>
              <Form.Control 
                type="text" 
                placeholder={t('subscribeForm.last_name')} 
                value={surname}
                onChange={handleSurnameChange}
                maxLength="40"
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>{t('subscribeForm.email')}</Form.Label>
              <Form.Control 
                type="email" 
                placeholder={t('subscribeForm.email')} 
                value={email}
                onChange={handleEmailChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formPhone">
              <Form.Label>{t('subscribeForm.phone')}</Form.Label>
              <Form.Control 
                type="tel" 
                placeholder="(xxx) xxx xx xx" 
                value={phone}
                onChange={handlePhoneChange}
                maxLength="30"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={12}>
            <Form.Group controlId="formTerms">
              <Form.Check 
                type="checkbox"
                label={t('subscribeForm.terms')}
                className="subscribe-checkbox"
              />
            </Form.Group>

            <Form.Group controlId="formNewsletter" className="mt-2">
              <Form.Check 
                type="checkbox"
                label={t('subscribeForm.newsletter')}
                className="subscribe-checkbox"
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="text-center mt-5">
          <Button 
            variant="danger" 
            type="submit" 
            className="subscribe-button w-100" 
            disabled={!isFormValid}
          >
            {t('subscribeForm.register')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SubscribeForm;
