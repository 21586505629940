import React, { useState, useEffect } from 'react';
import './CityClock.css';
import { useTranslation } from 'react-i18next'; // i18n kullanımı

const cities = [
  { name: 'Istanbul', timezoneOffset: 3 }, // UTC+3
  { name: 'Paris', timezoneOffset: 1 },    // UTC+1
  { name: 'New York', timezoneOffset: -4 },// UTC-4
  { name: 'Tokyo', timezoneOffset: 9 },    // UTC+9
  { name: 'Sydney', timezoneOffset: 10 },  // UTC+10
  { name: 'Moscow', timezoneOffset: 3 },   // UTC+3
  { name: 'London', timezoneOffset: 0 },   // UTC+0
  { name: 'Berlin', timezoneOffset: 1 },   // UTC+1
];

const DigitalClock = () => {
  const [currentTimes, setCurrentTimes] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const updateTimes = () => {
      const newTimes = {};
      cities.forEach(city => {
        const currentTime = new Date();
        const utcTime = currentTime.getTime() + currentTime.getTimezoneOffset() * 60000; // Get UTC time
        const cityTime = new Date(utcTime + city.timezoneOffset * 3600 * 1000); // Adjust to city timezone
        const timeString = cityTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // Show hour and minute only
        newTimes[city.name] = timeString;
      });
      setCurrentTimes(newTimes);
    };

    // Initial time update
    updateTimes();
    
    // Set interval to update times every second
    const interval = setInterval(updateTimes, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className="flip-clock-container">
      {cities.map(city => (
        <div key={city.name} className="flip-clock">
          <h3>{t(`cityNames.${city.name.toLowerCase()}`)}</h3> {/* Şehir adlarını çevir */}
          <div className="flip-container">
            {/* Saat */}
            <div className="flip-card">
              <div className="flip-front">{currentTimes[city.name]?.slice(0, 2)}</div>
              <div className="flip-back">{currentTimes[city.name]?.slice(0, 2)}</div>
            </div>
            {/* Saat ve dakika arasına ":" ekle */}
            <div className="colon">:</div>
            {/* Dakika */}
            <div className="flip-card">
              <div className="flip-front">{currentTimes[city.name]?.slice(3, 5)}</div>
              <div className="flip-back">{currentTimes[city.name]?.slice(3, 5)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DigitalClock;
