import React from 'react'
import YouTubeChannel from '../components/YoutubeChannel';
import FeaturedVideos from '../components/FeaturedVideos';
import BlogSection from '../components/BlogSection';
import SubsEmagazine from '../components/SubsEmagazine';
import Breadcrumb from '../components/Breadcrumb';
const Video = () => {
  return (
    <div>
        <div className='container mt-5'>
      <Breadcrumb />
      <FeaturedVideos />
      <YouTubeChannel />
      <BlogSection />
      </div>
      <SubsEmagazine />
    </div>
  )
}

export default Video