import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactPage.css';
import Breadcrumb from './Breadcrumb';
import { useTranslation } from 'react-i18next'; // i18n çeviri kancası

const ContactPage = () => {
  const { t } = useTranslation(); // i18n kullanımı
  const [isMapVisible, setIsMapVisible] = useState(false); // Haritanın görünür olup olmadığını takip etmek için state
  const mapContainerRef = useRef(null); // Harita div'ini takip etmek için ref
  const [showPopup, setShowPopup] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new URLSearchParams({
        formType: 'contact',
        fullName: formData.fullName,
        email: formData.email,
        subject: formData.subject,
        message: formData.message,
      });

      const response = await fetch("https://fikirpaneli.com.tr/send_mail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formDataToSend.toString(),
      });

      const data = await response.text();
      if (data.trim() === 'success') {
        setResponseMessage("Mail başarıyla gönderildi!");
      } else {
        setResponseMessage(`E-posta gönderiminde bir sorun oluştu: ${data}`);
      }

      setShowPopup(true);
    } catch (error) {
      setResponseMessage("Form gönderimi sırasında bir hata oluştu.");
      setShowPopup(true);
      console.error("Hata:", error);
    }
  };

  useEffect(() => {
    // Harita iframe'ini lazy-load etmek için IntersectionObserver kullanımı
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsMapVisible(true);
          observer.disconnect(); // Harita bir kez yüklendikten sonra observer'ı durdur
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (mapContainerRef.current) {
      observer.observe(mapContainerRef.current);
    }

    return () => {
      if (mapContainerRef.current) {
        observer.unobserve(mapContainerRef.current);
      }
    };
  }, []);

  return (
    <div className="container mt-5 contact-page">
      <Breadcrumb />
      <div className="row">
        <div className="col-lg-7 mb-4">
          <div className="contact-card shadow-sm border-0">
            <div className="contact-card-body">
              <h5 className="contact-card-title mb-4">{t('contactPage.contactInformation')}</h5>
              <table className="table contact-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">
                      <i className="fas fa-map-marker-alt text-danger me-2"></i>
                      {t('contactPage.address')}:
                    </td>
                    <td>{t('contactPage.addressDetail')}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">
                      <i className="fas fa-phone-alt text-danger me-2"></i>
                      {t('contactPage.phone')}:
                    </td>
                    <td>{t('contactPage.phoneDetail')}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">
                      <i className="fas fa-envelope text-danger me-2"></i>
                      {t('contactPage.email')}:
                    </td>
                    <td>{t('contactPage.emailDetail')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="map-container mt-4" ref={mapContainerRef}>
            {isMapVisible && (
              <iframe
                src="https://www.google.com/maps/embed?pb=YOUR_GOOGLE_MAPS_EMBED_LINK"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps"
              ></iframe>
            )}
          </div>
        </div>

        <div className="col-lg-5">
          <p className="mb-4 text-start text-muted">{t('contactPage.contactFormDescription')}</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label text-muted text-start">
                {t('contactPage.fullName')}
              </label>
              <input
                type="text"
                name="fullName"
                onChange={handleChange}
                className="form-control"
                id="fullName"
                placeholder={t('contactPage.fullName')}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label text-muted text-start">
                {t('contactPage.emailAddress')}
              </label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                className="form-control"
                id="email"
                placeholder={t('contactPage.emailAddress')}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label text-muted text-start">
                {t('contactPage.subject')}
              </label>
              <input
                type="text"
                name="subject"
                onChange={handleChange}
                className="form-control"
                id="subject"
                placeholder={t('contactPage.subject')}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label text-muted text-start">
                {t('contactPage.message')}
              </label>
              <textarea
                name="message"
                className="form-control"
                onChange={handleChange}
                id="message"
                rows="5"
                placeholder={t('contactPage.message')}
                required
              ></textarea>
            </div>
            <div className="form-check mb-3">
              <input type="checkbox" className="form-check-input" id="consent" required />
              <label className="form-check-label text-muted text-start" htmlFor="consent">
                {t('contactPage.consentText')}
              </label>
            </div>
            <button type="submit" className="btn btn-danger w-100 mb-5 btn-contact">
              {t('contactPage.submit')}
            </button>
          </form>
          {showPopup && (
            <div
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                padding: "15px",
                backgroundColor: responseMessage.includes("başarıyla") ? "green" : "red",
                color: "white",
                borderRadius: "5px",
              }}
            >
              {responseMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
