import React from 'react'
import BlogPage from '../components/BlogPage'
const Blog = () => {
  return (
    <div>
        <BlogPage />
    </div>
  )
}

export default Blog